.dungeon-scene {
  background-color: #000;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.dungeon {
  transition: all 0.4s ease-in;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-color: #000;
}
.dungeon ._overlay {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%); */
}
.dungeon ._zone {
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dungeon ._zone ._heading {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Saira Condensed';
}
.dungeon ._zone ._heading h1,
.dungeon ._zone ._heading h2 {
  font-weight: normal;
}
.dungeon ._zone ._heading p {
  font-size: 2vh;
}
.dungeon ._zone ._heading ._rooms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;
}
.dungeon ._zone ._heading ._rooms span {
  display: block;
  background-color: #fff;
  width: 1vh;
  height: 1vh;
  margin: 1vh;
  border-radius: 50%;
  transition: all 0.2s ease-in;
  box-shadow: 0 1px 3px #000000;
}
.dungeon ._zone ._heading ._rooms span.has-icon {
  width: 4vh;
  height: 4vh;
  background-color: #f1c40f;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  line-height: 4vh;
  color: #fff;
}
.dungeon ._zone ._heading ._rooms span.has-icon .custom-icon:before {
  font-size: 2vh;
}
.dungeon ._zone ._heading ._rooms span.is-active {
  transform: scale(1.8);
}
.dungeon ._zone ._heading ._rooms span.is-active,
.dungeon ._zone ._heading ._rooms span.is-complete,
.dungeon ._zone ._heading ._rooms span.is-ended {
  background-color: #e74c3c;
}
.dungeon ._zone ._heading ._rooms span.is-active,
.dungeon ._zone ._heading ._rooms span.is-complete {
  position: relative;
}
.dungeon ._zone ._heading ._rooms span.is-active:before,
.dungeon ._zone ._heading ._rooms span.is-complete:before {
  content: "";
  position: absolute;
  top: -1vh;
  left: 50%;
  margin-left: -0.3vh;
  width: 0;
  height: 0;
  border-style: solid;
  display: block;
  border-color: #f1c40f transparent transparent transparent;
  border-width: .6vh .3vh 0 .3vh;
}
.dungeon ._zone ._heading ._rooms span.is-active.has-icon:before,
.dungeon ._zone ._heading ._rooms span.is-complete.has-icon:before {
  top: -1.4vh;
  margin-left: -0.5vh;
  border-width: 1vh .5vh 0 .5vh;
}
.dungeon ._zone ._modal {
  height: 50vh;
  padding: 0 10vh;
  box-shadow: 0 1px 30px #000000;
  border-radius: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.dungeon ._zone ._modal h1,
.dungeon ._zone ._modal h2 {
  font-weight: normal;
}
.dungeon ._zone ._modal p {
  font-size: 2.4vh;
}
.dungeon ._zone ._modal p em {
  display: block;
  font-style: normal;
  font-size: 5vh;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: .4vh;
  text-shadow: 0 1px 3px #000000;
}
.dungeon ._zone ._arena {
  height: 70vh;
}
.dungeon ._zone ._arena ._battle {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dungeon ._zone ._arena ._battle ._log {
  width: 10vh;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.dungeon ._zone ._arena ._battle ._log > div {
  display: flex;
  flex-direction: column;
}
.dungeon ._zone ._arena ._battle ._character {
  position: relative;
  margin: 0 10vh;
}
.dungeon ._zone ._arena ._battle ._character.is-taking-turn {
  perspective: 1000px;
}
.dungeon ._zone ._arena ._battle ._character ._splash {
  background-color: #f1c40f;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10vh;
  height: 10vh;
  margin: -5vh 0 0 -5vh;
  line-height: 10vh;
  color: #fff;
  animation: splashDamage 0.6s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
  text-shadow: 0 0 1px #000000;
  user-select: none;
  font-size: 1.4em;
  -webkit-clip-path: polygon(14% 10%, 53% 35%, 61% 6%, 70% 30%, 80% 22%, 77% 36%, 86% 36%, 80% 44%, 97% 48%, 78% 57%, 90% 88%, 60% 70%, 55% 86%, 46% 70%, 15% 91%, 32% 63%, 8% 54%, 27% 41%, 16% 32%, 29% 34%);
  clip-path: polygon(14% 10%, 53% 35%, 61% 6%, 70% 30%, 80% 22%, 77% 36%, 86% 36%, 80% 44%, 97% 48%, 78% 57%, 90% 88%, 60% 70%, 55% 86%, 46% 70%, 15% 91%, 32% 63%, 8% 54%, 27% 41%, 16% 32%, 29% 34%);
}
.dungeon ._zone ._arena ._footer {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dungeon ._zone ._arena ._footer ._hero-log {
  display: flex;
}
.dungeon ._zone ._arena ._footer ._hero-log ._hero-power {
  color: #fff;
  display: block;
  margin: 0 5vh;
  opacity: .8;
}
.dungeon ._zone ._arena ._footer ._hero-log ._hero-power span {
  font-size: 4vh;
  display: block;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-left ._hero-power:nth-child(1) {
  opacity: .3;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-left ._hero-power:nth-child(2) {
  opacity: .6;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-left ._hero-power:nth-child(3) {
  opacity: 1;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-right ._hero-power:nth-child(1) {
  opacity: 1;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-right ._hero-power:nth-child(2) {
  opacity: .6;
}
.dungeon ._zone ._arena ._footer ._hero-log.is-right ._hero-power:nth-child(3) {
  opacity: .3;
}
.dungeon ._zone ._arena ._footer ._hero-abilities {
  margin: 0 5vh;
}
.dungeon ._zone ._arena ._footer ._autofight {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.dungeon ._zone ._arena ._footer ._autofight a {
  display: inline-block;
  padding: 1vh;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

/* 1st cubic-bezier(0.895, 0.030, 0.935, 0.095) */
/* 2nd cubic-bezier(0.940, -0.325, 1.000, 1.650) */
/* 3rd cubic-bezier(1.000, -0.600, 0.930, 0.575) */

.anim-strike {
  z-index: 1;
  animation: abilityStrike 0.4s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-strike-inverse {
  z-index: 1;
  animation: abilityStrikeInverse 0.4s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-execute {
  z-index: 1;
  animation: abilityExecute 0.4s cubic-bezier(1, -0.6, 0.93, 0.575) forwards;
}
.anim-cripple {
  z-index: 1;
  animation: abilityCripple 0.4s cubic-bezier(1, -0.6, 0.93, 0.575) forwards;
}
.anim-bladestorm {
  z-index: 1;
  animation: abilityBladestorm 0.4s cubic-bezier(0.255, 0.54, 0.425, 0.815) forwards;
}
.anim-shield {
  z-index: 1;
  animation: abilityShield 0.4s cubic-bezier(0.255, 0.54, 0.425, 0.815) forwards;
}
.anim-shake-low {
  animation: shakeLow 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-shake-mid {
  animation: shakeMid 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-shake-high {
  animation: shakeHigh 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
@keyframes shakeLow {
  10%,
  90% {
    transform: translate3d(-0.25vh, -0.25vh, 0);
  }
  20%,
  80% {
    transform: translate3d(0.5vh, 0.5vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-1vh, -1vh, 0);
  }
  40%,
  60% {
    transform: translate3d(1vh, 1vh, 0);
  }
}
@keyframes shakeMid {
  10%,
  90% {
    transform: translate3d(-0.5vh, -0.5vh, 0);
  }
  20%,
  80% {
    transform: translate3d(1vh, 1vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-2vh, -2vh, 0);
  }
  40%,
  60% {
    transform: translate3d(2vh, 2vh, 0);
  }
}
@keyframes shakeHigh {
  10%,
  90% {
    transform: translate3d(-1vh, -1vh, 0);
  }
  20%,
  80% {
    transform: translate3d(2vh, 2vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4vh, -4vh, 0);
  }
  40%,
  60% {
    transform: translate3d(4vh, 4vh, 0);
  }
}
@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes splashDamage {
  0% {
    opacity: 0;
    transform: scale(1) translate3d(0, 0, 0);
  }
  5% {
    opacity: 1;
    transform: scale(3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(3) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
@keyframes flashDamage {
  0% {
    opacity: .4;
  }
  100% {
    opacity: 0;
  }
}
@keyframes abilityStrike {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  40% {
    transform: translate3d(0, 0, 0) scale(1.2) rotateY(40deg);
  }
  50% {
    transform: translate3d(27vh, 0, 0) scale(1.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(0);
  }
}
@keyframes abilityStrikeInverse {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  40% {
    transform: translate3d(0, 0, 0) scale(1.2) rotateY(40deg);
  }
  50% {
    transform: translate3d(-27vh, 0, 0) scale(1.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(0);
  }
}
@keyframes abilityCripple {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(20vh, 0, 0) rotateZ(50deg) rotateX(-30deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes abilityExecute {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(32vh, 0, 0) rotateY(20deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes abilityBladestorm {
  0% {
    transform: translate3d(0, 0, 0) rotateY(0);
  }
  50% {
    transform: translate3d(27vh, 0, 0) rotateY(360deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotateY(0);
  }
}
@keyframes abilityShield {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1) rotateX(10deg) rotateY(6deg);
    box-shadow: 0 10px 20px 0 #2980b9;
  }
  80% {
    transform: scale(1.1) rotateX(-10deg) rotateY(-12deg);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes openChest {
  0% {
    transform: translate3d(0, 0, 0);
  }
  30% {
    transform: translate3d(0, -20vh, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, -10vh, 0) scale(0.2);
    opacity: 0;
  }
}
@keyframes popReward {
  0% {
    transform: translate3d(0, 2vh, 0);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.1);
    opacity: 1;
  }
}
