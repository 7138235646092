@font-face {
  font-family: "Flaticon";
  src: url("./fonts/icons/Flaticon.eot");
  src: url("./fonts/icons/Flaticon.eot?#iefix") format("embedded-opentype"), url("./fonts/icons/Flaticon.woff") format("woff"), url("./fonts/icons/Flaticon.ttf") format("truetype"), url("./fonts/icons/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./fonts/icons/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^="fl-"]:before,
[class*=" fl-"]:before,
[class^="fl-"]:after,
[class*=" fl-"]:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
}
.fl-archery:before {
  content: "\f100";
}
.fl-axe:before {
  content: "\f101";
}
.fl-axes:before {
  content: "\f102";
}
.fl-banner:before {
  content: "\f103";
}
.fl-castle:before {
  content: "\f104";
}
.fl-catapult:before {
  content: "\f105";
}
.fl-chest:before {
  content: "\f106";
}
.fl-coach:before {
  content: "\f107";
}
.fl-crossbow:before {
  content: "\f108";
}
.fl-crown:before {
  content: "\f109";
}
.fl-executioner:before {
  content: "\f10a";
}
.fl-flag:before {
  content: "\f10b";
}
.fl-fortress:before {
  content: "\f10c";
}
.fl-helmet:before {
  content: "\f10d";
}
.fl-horn:before {
  content: "\f10e";
}
.fl-joker:before {
  content: "\f10f";
}
.fl-king:before {
  content: "\f110";
}
.fl-knight:before {
  content: "\f111";
}
.fl-lute:before {
  content: "\f112";
}
.fl-mill:before {
  content: "\f113";
}
.fl-princess:before {
  content: "\f114";
}
.fl-quiver:before {
  content: "\f115";
}
.fl-scroll:before {
  content: "\f116";
}
.fl-shield:before {
  content: "\f117";
}
.fl-shield-1:before {
  content: "\f118";
}
.fl-ship:before {
  content: "\f119";
}
.fl-spears:before {
  content: "\f11a";
}
.fl-stained-glass-window:before {
  content: "\f11b";
}
.fl-sword:before {
  content: "\f11c";
}
.fl-sword-1:before {
  content: "\f11d";
}
.fl-sword-2:before {
  content: "\f11e";
}
.fl-swords:before {
  content: "\f11f";
}
.fl-torches:before {
  content: "\f120";
}
.fl-tower:before {
  content: "\f121";
}
.fl-tower-1:before {
  content: "\f122";
}
