.base {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.board {
  width: 100%;
  max-width: 80rem;
}

.boardMenu {
  display: flex;
  justify-content: center;
}

.boardMenu > div {
  padding: 2rem;
  font-weight: bold;
}

.boardMain {
  
}