.base {
  padding: 0 1vh;
  display: flex;
}

.frame {
  display: flex;
  background-color: #11100f;
  border-radius: 1vh;
  align-items: center;
  padding: 0 1vh;
  height: 3vh;
  margin: 0 1vh;
}
.frame img {
  max-width: 1.6vh;
}

.icon {
  display: flex;
  align-items: center;
}

.amount {
  margin-left: .6vh;
}