.base {
  height: 100%;
  background: url(../../../themes/default/bricks.jpg);
  background-size: 30%;
  background-position: center center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  position: relative;
  
  /* justify-content: space-between;
  flex-direction: column; */
}
.base:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%); 
}

.main {
  width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 1;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main::-webkit-scrollbar {
  width: 4px;
}

.main::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 4px;
}

.main::-webkit-scrollbar-thumb {
  background: #2d2b29; 
  border-radius: 4px;
}

.main::-webkit-scrollbar-thumb:hover {
  background: #f1b541; 
}

.wrapper {
  padding: 20vh 4vh;
}

/* .main {
  width: 100%;
  flex-grow: 1;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
} */

/* .footer {
  height: 20vh;
  position: relative;
  z-index: 1;
} */