.ability-button {
  display: inline-block;
  width: 10vh;
  height: 10vh;
  background-color: #27ae60;
  color: #fff;
  line-height: 10vh;
  box-shadow: 0 1px 3px #000000;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  user-select: none;
  margin: 1vh;
  position: relative;
}
.ability-button:hover {
  transform: scale(1.1);
  background-color: #f1c40f;
}
.ability-button:hover ._level {
  border-color: #f1c40f;
}
.ability-button:active {
  transition-duration: .02s;
  transform: scale(1);
}
.ability-button.is-active {
  transform: scale(0.9);
  background-color: #f1c40f;
}
.ability-button.is-active ._level {
  border-color: #f1c40f;
}
.ability-button ._icon {
  transition: all 0.2s ease-in-out;
  display: block;
}
.ability-button i:before {
  font-size: 5vh;
}
.ability-button ._cooldown {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  font-size: 3vh;
  line-height: 9.4vh;
}
.ability-button ._level {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 3vh;
  height: 3vh;
  background-color: #0b311b;
  border: 0.2vh solid #27ae60;
  line-height: 2.8vh;
  border-radius: 50%;
  font-size: 1.6vh;
}
.ability-button ._level.is-upgrade {
  background-color: #f1c40f;
}
