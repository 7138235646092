.base {
  background-color: #fff;
  border-radius: 1vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.8), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
}
.inner {
  border: .2vh solid rgba(255,255,255,.1);
  height: 100%;
  border-radius: 1vh;
}

.list {
  display: flex;
  flex-flow: row wrap;
  margin: -1vh;
}

.list > div {
  margin: 1vh;
}