.base {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1000;
  /* composes: anim-fadeIn from '../../../themes/default/animations.css'; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,1);
  background-image: url(../../../themes/default/bricks.jpg);
}

.modal {
  position: relative;
  /* width: 100%; */
  z-index: 1;
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 4.6rem;
  height: 4.6rem;
  background-color: #E7343F;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}