.base {
  user-select: none;
  text-align: center;
  position: relative;
}

.card {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
  padding: 4rem 2rem 2rem;
  font-weight: bold;
  position: relative;
  background-color: rgba(255,255,255,.8);
  border-radius: .5rem .5rem 0 0;
}

.card .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.card .loot {
  font-size: 2.4rem;
}

.notification {
  position: absolute;
  top: -3rem;
  left: 50%;
  margin-left: -3rem;
  width: 6rem;
  height: 6rem;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  border-radius: 50%;
  cursor: pointer;
  transition: .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.notification:hover {
  /* transform: scale(0.96); */
}

.notification.hasFinised {
  background-color: #ffc107;
}

.notificationIcon {
  z-index: 1;
}

.notificationProgress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  background-color: #ffc107;
  transition: .2s;
}