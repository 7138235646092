.base {
  display: flex;
}

.avatar {
  background-color: rgb(23, 23, 23);
  background-size: cover;
  display: block;
  margin: -3vh 0 -1vh;
  box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.8), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
  width: 10vh;
  height: 10vh;
  z-index: 1;
  position: relative;
}
.frame {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  background-image: url(../../../../themes/default/button_04_frame.png);
  background-size: cover;
}
.level {
  position: absolute;
  bottom: -1vh;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.level span {
  display: block;
  background-color: #11100f;
  border-radius: 50%;
  width: 3vh;
  height: 3vh;
  text-align: center;
  line-height: 3vh;
}

.info {
  
}

.name {
  margin: -2vh 0 1vh 2vh;
  font-size: 3vh;
  text-align: left;
}

.progress {
  background-color: #11100f;
  width: 24vh;
  
  margin-left: -1vh;
  border-radius: 1vh;
  padding: .2vh;
  /* border: .2vh solid #a29484; */
}

.progressValue {
  width: 80%;
  background-color: #e9a725;
  height: 1.4vh;
  border-radius: 1vh;
}