.base {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url(../../../themes/default/board.jpg);
  /* box-shadow: 0 10px 6px -6px rgba(0,0,0,.4); */
  box-shadow: 0 23px 38px 54px rgb(0, 0, 0);
  background-size: auto 100%;
  background-position: center center;

  margin: 4vh;
  border-radius: 1vh;
}

.base > div {
  display: flex;
  justify-content: center;
  flex: 1 1 33.33%;
}

.base > div:first-child {
  justify-content: flex-start;
}
.base > div:last-child {
  justify-content: flex-end;
}

.link {
  background-color: rgb(23, 23, 23);
  background-size: cover;
  display: block;
  border-radius: 50%;
  margin: -3vh 1vh -1vh;
  box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.8), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
  /* transform: scale(.8); */
  cursor: pointer;
}
.link:hover {

}

.link span {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  background-image: url(../../../themes/default/frame_c_01.png);
  background-size: cover;
  /* transform: scale(1.1); */
}