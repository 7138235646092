.base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
}

.title {
  position: absolute;
  top: 1vh;
  left: 1vh;
  right: 1vh;
 
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
  border-radius: .8vh;
  font-size: 3vh;
  text-shadow: 0 1px 1px rgba(0,0,0,.9);
}

.icon {
  max-height: 10vh;
}

.action {
  position: absolute;
  bottom: 1vh;
  left: 1vh;
  right: 1vh;
  background-color: rgba(255,255,255,.2);
  box-shadow: 0 10px 6px -6px rgba(0,0,0,.4);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
  border-radius: .8vh;
  font-size: 2.4vh;
  text-shadow: 0 1px 1px rgba(0,0,0,.9);
  cursor: pointer;
  transition: all .2s;
}

.action:hover {
  background-color: rgba(255,255,255,.6);
}