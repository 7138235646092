.anim-flash {
  position: relative;
}
.anim-flash:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1vh;
  background-color: #fff;
  animation: flashDamage 0.4s forwards;
}

.anim-dead {
  z-index: 1;
  animation: dead 0.4s forwards;
}

.anim-fadeIn {
  z-index: 1;
  animation: fadeIn 0.4s forwards;
}

.anim-splash {
  position: absolute;
  top: 50%;
  margin-top: -10vh;
  left: 0;
  width: 20vh;
  height: 20vh;
  animation: splashDamage 0.6s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-splash path {
  fill: #e9a725;
}
.anim-splash span {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vh;
}

.anim-ability-strike {
  z-index: 1;
  animation: abilityStrike 0.4s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-ability-strike-inverse {
  z-index: 1;
  animation: abilityStrikeInverse 0.4s cubic-bezier(0.895, 0.03, 0.935, 0.095)
    forwards;
}
.anim-ability-execute {
  z-index: 1;
  animation: abilityExecute 0.4s cubic-bezier(1, -0.6, 0.93, 0.575) forwards;
}
.anim-ability-cripple {
  z-index: 1;
  animation: abilityCripple 0.4s cubic-bezier(1, -0.6, 0.93, 0.575) forwards;
}
.anim-ability-bladestorm {
  z-index: 1;
  animation: abilityBladestorm 0.4s cubic-bezier(0.255, 0.54, 0.425, 0.815)
    forwards;
}
.anim-ability-shield {
  z-index: 1;
  animation: abilityShield 0.4s cubic-bezier(0.255, 0.54, 0.425, 0.815) forwards;
}
.anim-shake-low {
  animation: shakeLow 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-shake-mid {
  animation: shakeMid 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
.anim-shake-high {
  animation: shakeHigh 0.2s cubic-bezier(0.895, 0.03, 0.935, 0.095) forwards;
}
@keyframes shakeLow {
  10%,
  90% {
    transform: translate3d(-0.25vh, -0.25vh, 0);
  }
  20%,
  80% {
    transform: translate3d(0.5vh, 0.5vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-1vh, -1vh, 0);
  }
  40%,
  60% {
    transform: translate3d(1vh, 1vh, 0);
  }
}
@keyframes shakeMid {
  10%,
  90% {
    transform: translate3d(-0.5vh, -0.5vh, 0);
  }
  20%,
  80% {
    transform: translate3d(1vh, 1vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-2vh, -2vh, 0);
  }
  40%,
  60% {
    transform: translate3d(2vh, 2vh, 0);
  }
}
@keyframes shakeHigh {
  10%,
  90% {
    transform: translate3d(-1vh, -1vh, 0);
  }
  20%,
  80% {
    transform: translate3d(2vh, 2vh, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4vh, -4vh, 0);
  }
  40%,
  60% {
    transform: translate3d(4vh, 4vh, 0);
  }
}
@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes splashDamage {
  0% {
    opacity: 0;
    transform: scale(0.1) translate3d(0, 0, 0);
  }
  5% {
    opacity: 1;
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.1) translate3d(0, 0, 0);
  }
}
@keyframes flashDamage {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dead {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes abilityStrike {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  40% {
    transform: translate3d(0, 0, 0) scale(1.2) rotateY(40deg);
  }
  50% {
    transform: translate3d(27vh, 0, 0) scale(1.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(0);
  }
}
@keyframes abilityStrikeInverse {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  40% {
    transform: translate3d(0, 0, 0) scale(1.2) rotateY(40deg);
  }
  50% {
    transform: translate3d(-27vh, 0, 0) scale(1.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1) rotateY(0);
  }
}
@keyframes abilityCripple {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(20vh, 0, 0) rotateZ(50deg) rotateX(-30deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes abilityExecute {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(32vh, 0, 0) rotateY(20deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes abilityBladestorm {
  0% {
    transform: translate3d(0, 0, 0) rotateY(0);
  }
  50% {
    transform: translate3d(27vh, 0, 0) rotateY(360deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotateY(0);
  }
}
@keyframes abilityShield {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1) rotateX(10deg) rotateY(6deg);
    box-shadow: 0 10px 20px 0 #2980b9;
  }
  80% {
    transform: scale(1.1) rotateX(-10deg) rotateY(-12deg);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes openChest {
  0% {
    transform: translate3d(0, 0, 0);
  }
  30% {
    transform: translate3d(0, -20vh, 0) scale(0.5);
  }
  100% {
    transform: translate3d(0, -10vh, 0) scale(0.2);
    opacity: 0;
  }
}
@keyframes popReward {
  0% {
    transform: translate3d(0, 2vh, 0);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.1);
    opacity: 1;
  }
}
