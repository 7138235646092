.arena {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

.arena > div {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
}

.player {
  
}

.enemy {

}

.bottom {
  position: absolute;
  bottom: -6vh;
  left: 0;
  width: 100%;
}

.abilities {
  display: flex;
  justify-content: center;
}

.abilities > div {
  display: flex;
  justify-content: center;
  background-color: #000;
  border-radius: 10vh;
  padding: .4vh;
  background-image: url(../../themes/default/board.jpg);
}

.abilities > div > div {
  margin: .4vh;
}