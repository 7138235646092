* {
  box-sizing: border-box;
}

body,
html,
#root {
  height: 100%;
  overflow: hidden;
  /* background-color: #000; */
}

body {
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.9);
}

body,
input,
select,
button,
textarea {
  font-family: "Passion One", sans-serif;
  font-size: 2vh;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
}

.keycode {
  position: absolute;
  left: 50%;
  margin-left: -1.5vh;
  bottom: -1vh;
  width: 3vh;
  height: 3vh;
  line-height: 3vh;
  border-radius: .6vh;
  font-size: 2vh;
  background-color: #d3d3d3;
  box-shadow: 0 3px 0px #4e4e4e, 0 1px 3px #676767;
  color: #505050;
}
.button {
  padding: 1vh 2vh;
  display: inline-block;
  cursor: pointer;
  font-size: 1.8vh;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  user-select: none;
  text-decoration: none;
  border: 0;
}
.button.is-primary {
  background-color: #27ae60;
  color: #fff;
  box-shadow: 0 1px 3px #000000;
  text-shadow: 0 1px 3px #000000;
}
.button.is-primary:disabled {
  opacity: .2;
}
