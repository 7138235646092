.base {
  width: 10vh;
  height: 10vh;
  color: #fff;
  background-color: rgb(23, 23, 23);
  background-size: cover;
  display: block;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.8), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
  background-image: url(../../themes/default/skill.PNG);
  cursor: pointer;
  user-select: none;
}

.frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vh;
  height: 10vh;
  background-image: url(../../themes/default/button_06_frame.png);
  background-size: cover;
}

.frame > span {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  border-radius: 50%;
  font-size: 3vh;
  align-items: center;
  justify-content: center;
}