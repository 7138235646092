.card {
  background-color: rgba(38,38,38,.8);
  border-radius: 1vh;
  color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.4), 0px 1px 1px 0px rgba(0,0,0,0.28), 0px 2px 1px -1px rgba(0,0,0,0.24);
  user-select: none;
  transition: all .2s;
}

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  position: absolute;
  top: 1vh;
  left: 1vh;
  right: 1vh;
 
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
  border-radius: .8vh;
  font-size: 3vh;
}
.isForBattle .title {
  top: -5vh;
  opacity: 0;
  transition: all .2s;
}
.isForBattle.showTitle .title {
  opacity: 1;
}

.icon {
  max-height: 28vh;
  margin-top: 4vh;
  /* max-height: 39vh;
  margin-top: 8vh;
  margin-left: -3vh; */
}
.isForBattle .icon {
  max-height: 30vh;
    margin-top: -2vh;
}

.action {
  position: absolute;
  bottom: 1vh;
  left: 1vh;
  right: 1vh;
  background-color: rgba(255,255,255,.2);
  box-shadow: 0 10px 6px -6px rgba(0,0,0,.4);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
  border-radius: .8vh;
  font-size: 2.4vh;
  text-shadow: 0 1px 1px rgba(0,0,0,.9);
  cursor: pointer;
  transition: all .2s;
}

.action:hover {
  background-color: rgba(255,255,255,.6);
}

.footer {
  padding: .8vh 1vh;
  display: flex;
  align-items: center;
  line-height: 100%;
  justify-content: space-between;
  cursor: pointer;
}
.card:hover {
  background-color: rgba(233, 167, 37, .7);
}
.card:hover .footer > div:nth-child(2) {
  color: #fff;
}

.footer > div:nth-child(1) {
  display: flex;
  align-items: center;
}
.footer > div:nth-child(1) > div {
  margin: 0 .4vh;
}
.footer > div:nth-child(2) {
  color: #e9a725;
}

.footer img {
  max-width: 1.6vh;
}

.bars {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1vh;
}

.healthbar {
  background-color: #000;
  padding: .2vh;
  position: relative;
}

.healthbarValue {
  height: 1.2vh;
  background-color: #4fc508;
  background: linear-gradient(to bottom, #56d609 0%,#388d04 100%);
  transition: all .2s;
  z-index: 1;
  position: relative;
}

.healthbarValueDelay {
  position: absolute;
  left: 0;
  height: 1.2vh;
  transition: all .2s;
  background: linear-gradient(to bottom, #56d609 0%,#388d04 100%);
  opacity: .4;
}

.healthbarLabel {
  position: absolute;
  right: .4vh;
  top: 0;
  font-size: 1.4vh;
  z-index: 1;
}

.manabar {
  background-color: #000;
  padding: .2vh;
  margin-top: .2vh;
}

.manabarValue {
  height: .55vh;
  background-color: #0b83c1;
  background: linear-gradient(to bottom, #0d89ca 0%, #045e8d 100%);
}