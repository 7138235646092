.base {
  background-color: #fff;
  height: 5vh;
  animation: fadeIn .2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}