.character {
  position: relative;
  width: 30vh;
  height: 40vh;
  border-radius: 1vh;
  transition: all 0.4s ease-out;
  box-shadow: 0 1px 30px rgba(0,0,0,1);
  background-color: rgba(255, 255,255,.1);
  border: .2vh solid rgba(255,255,255,.2);
}
.character.has-turn:after {
  content: "";
  position: absolute;
  top: -4vh;
  left: 50%;
  margin-left: -1vh;
  width: 0;
  height: 0;
  border-style: solid;
  display: block;
  border-color: #f1c40f transparent transparent transparent;
  border-width: 2vh 1vh 0 1vh;
}
.character.has-turn.is-enemy:after {
  border-top-color: #e74c3c;
}
.character.is-dead {
  opacity: 0;
}
.character.has-defence {
  border-color: #2980b9;
  border-width: .4vh;
  box-shadow: 0 0 50px 0 #2980b9;
}
.character.is-elite {
  border-color: #f1c40f;
  border-width: .4vh;
  box-shadow: 0 0 20px 0 #f1c40f;
}
.character.is-beeing-hit {
  border-color: #e74c3c;
}
.character.is-beeing-hit:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1vh;
  background-color: #fff;
  animation: flashDamage .4s forwards;
}
.character.is-beeing-hit ._health {
  transition: all 0.4s ease-in;
  transform: scale(1.2);
  z-index: 100;
}
.character ._card {
  height: 100%;
  perspective: 1000px;
}
.character ._card ._flipper {
  width: 100%;
  height: 100%;
  transition: 0.4s cubic-bezier(0.255, 0.54, 0.425, 0.815);
  transform-style: preserve-3d;
  position: relative;
  border-radius: .8vh;
  cursor: pointer;
  /* box-shadow: 0 1px 30px rgba(0,0,0,1); */
}
.character ._card.is-flipped ._flipper {
  transform: rotateY(180deg);
}
.character ._card ._frontside,
.character ._card ._backside {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.character ._card ._frontside {
  z-index: 2;
  transform: rotateY(0deg);
}
.character ._card ._backside {
  transform: rotateY(180deg);
}
.character ._card ._image {
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: .8vh;
}
.character ._card ._stats {
  width: 100%;
  height: 100%;
  border-radius: .8vh;
}
.character ._card ._stats ._table {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2vh;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  border-radius: 1vh;
  font-family: 'Saira Condensed';
}
.character ._card ._stats ._table > div {
  display: flex;
  align-items: center;
  padding: .3vh 0;
}
.character ._card ._stats ._table > div span {
  flex-grow: 1;
  font-size: 2vh;
}
.character ._card ._stats ._table > div span:last-child {
  text-align: right;
  font-size: 3vh;
}
.character ._card ._stats ._table > div._last-hit span {
  color: #f1c40f;
  font-weight: bold;
}
.character ._card ._stats ._table > div._last-hit span:last-child {
  font-size: 4vh;
}
.character ._name {
  color: #fff;
  font-size: 3.4vh;
  padding-top: 2vh;
  text-shadow: 0 0 10px #000000;
}
.character ._debuff {
  position: absolute;
  top: -2.6rem;
  left: 50%;
  margin-left: -0.7rem;
}
.character ._debuff span {
  display: block;
  background-color: #e74c3c;
  width: 1.4rem;
  height: 1.4rem;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 1rem;
  -webkit-border-bottom-right-radius: 1rem;
  -webkit-border-bottom-left-radius: 1rem;
  -webkit-transform: rotate(45deg);
}
.character ._blobs {
  position: absolute;
  bottom: -3vh;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.character ._defence,
.character ._health {
  width: 5vh;
  height: 5vh;
  background-color: #e74c3c;
  color: #fff;
  border-radius: 50%;
  font-size: 2.8vh;
  line-height: 5vh;
  text-shadow: 0 1px 1px #000000;
  margin: 0 .4vh;
  z-index: 1;
  box-shadow: 0 1px 30px rgba(0,0,0,1);
  animation: grow .4s ease-in-out forwards;
}
.character ._defence {
  background-color: #2980b9;
}
