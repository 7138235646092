._rewards {
  height: 40vh;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30vh;
}
._rewards .reward {
  position: relative;
}
._rewards .reward ._chest {
  width: 24vh;
  height: 20vh;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  box-shadow: 0 1px 30px #000000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vh;
  margin: 0 2vh;
  transition: all 0.2s ease-in;
  position: relative;
  cursor: pointer;
}
._rewards .reward ._chest .custom-icon:before {
  font-size: 14vh;
}
._rewards .reward ._chest:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.3);
}
._rewards .reward ._chest:active {
  transform: scale(1);
}
._rewards .reward ._label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-transform: uppercase;
  font-size: 2.4vh;
  pointer-events: none;
}
._rewards .reward ._label em {
  font-style: normal;
  font-size: 5vh;
  display: block;
}
._rewards .reward.is-hitChance ._label,
._rewards .reward.is-critChance ._label,
._rewards .reward.is-critDamage ._label,
._rewards .reward.is-damage ._label {
  color: #f1c40f;
}
._rewards .reward.is-defence ._label {
  color: #2980b9;
}
._rewards .reward.is-health ._label {
  color: #e74c3c;
}
._rewards .reward.is-active ._chest {
  animation: openChest 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
._rewards .reward.is-active ._label {
  animation: popReward 3s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}
